@media (min-width: 300px) and (min-width: 300px) {
  .filePicker {
    background-color: transparent;
    border: solid 2px #8A979D;
    box-shadow: 5px 5px 5px #DBDAD0;
    color: #1E3D59;
    cursor: pointer;
    border-radius: 10px;
    text-align: center;
    display: block;
    padding: 0 15px;
    margin: 70px 0 0 2%;
    width: 200px;
    font-size: 1.5rem;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .filePicker :hover {
    color: white;
    background-color: #1E3D59;
    border: solid 2px #1E3D59;
  }
}
@media (min-width: 300px) {
  .fileInput {
    display: none;
  }
}
@media (min-width: 700px) {
  .filePicker {
    margin: 90px 0 0 2%;
  }
}
.button {
  background-color: #1e3d59;
  color: white;
  border-radius: 4px;
  margin-top: 5px;
}

.payment-form {
  width: 307px;
  padding: 5px 10px 5px 10px;
  background-color: white;
}

@media (min-width: 300px) {
  .burger {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 11;
  }
  .burger :focus {
    outline: none;
  }
  .burger div {
    width: 2rem;
    height: 0.25rem;
    background: #fff;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
  }

  .bar1 {
    transform: rotate(45deg);
  }

  .bar2 {
    opacity: 0;
  }

  .bar3 {
    transform: rotate(-45deg);
  }
}
@media (min-width: 1000px) {
  .burger {
    display: none;
  }
}
.sidebar-logo {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}

.sidebar-overlay {
  transition: 0.3s;
  position: fixed;
  top: 50px;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #8a979d80;
  opacity: 0.75;
  z-index: 9;
}

.nav-item {
  color: white;
  text-decoration: none;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 16px;
  width: 84%;
  border-radius: 15px;
  margin-bottom: 4px;
  cursor: pointer;
  user-select: none;
}

.nav-item-selected {
  background-color: #5e768b;
}

.nav-item:hover {
  color: white;
  background-color: #5e768b;
}

.sidebar {
  transition: 0.3s;
  left: 0;
  display: flex;
  min-width: 200px;
  height: 100vh;
  background-color: #36546e;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  z-index: 10;
}

@media only screen and (max-width: 1000px) {
  .sidebar-hide-left {
    left: -200px;
  }
}
.topbar {
  z-index: 11;
  height: 20px;
}

.logo {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .topbar {
    height: 50px;
  }

  .logo {
    display: block;
  }
}
.loading {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #282828;
  opacity: 0.7;
  z-index: 25;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reports-button {
  height: 40px;
  background-color: #1e3d59;
  border: solid 2px #1e3d59;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  font-size: 1.25rem;
}
.reports-button:focus {
  background-color: #1e3d59;
  border: solid 2px #1e3d59;
}
.reports-button:hover {
  background-color: #6894ba;
  border: solid 2px #1e3d59;
}
.reports-button:active {
  background-color: #1e3d59;
}

.reports-page-btn {
  width: 400px;
}

.btn-container {
  height: 400px;
  background-color: #edecea;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  padding: 5px 15px;
  border-radius: 5px;
}

@media (max-width: 500px) {
  .btn-container {
    width: 95%;
    min-width: 220px;
  }

  .reports-page-btn {
    width: 100%;
  }
}
.download-csv-btn {
  border: 2px solid #243c57;
  color: #243c57;
  text-decoration: none;
  width: 250px;
  height: 44px;
  padding: 5px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.download-csv-btn:hover {
  background-color: #E5EAEE;
}

.table-generator-history {
  font-size: 16px;
  width: 100%;
}

tr:nth-child(even) {
  background-color: #f7f5f2;
}

.dateCard:nth-child(odd) {
  background-color: #eae8e7;
}

@media (min-width: 300px) {
  .dateLabel {
    margin: 20px 20px 10px 20px;
  }

  .dateSelect {
    padding: 4px;
    width: 200px;
    height: 36px;
    font-size: 13px;
    border-radius: 4px;
  }

  .dateOptions {
    display: block;
    width: fit-content;
  }

  .dateCardButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
  }

  .dateCardsRefresh {
    font-size: 1rem;
    color: #008000;
    border: solid 2px green;
    border-radius: 5px;
    padding: 0 10px;
  }

  .dateCardsClose {
    color: gray;
    font-weight: bold;
    font-size: 2rem;
  }

  .total-generator-cards {
    font-size: 1.25rem;
  }

  .dateCard {
    border: solid 2px #eae8e7;
    border-radius: 5px;
    margin: 15px 0;
    padding: 5px;
  }

  .dateCardNumber {
    font-weight: bold;
  }
}
@media (min-width: 900px) {
  .total-generator-cards {
    font-size: 1.5rem;
  }
}
.inactive-cards-header {
  margin: 20px 0;
}

.inactive-cards-total {
  font-size: 1.5rem;
  margin-bottom: 14px;
}

@media (max-width: 900px) {
  .inactive-cards-header {
    text-align: center;
  }

  .inactive-cards-total {
    font-size: 1.25rem;
  }
}
.active-cards-total {
  font-size: 1.5rem;
}

@media (max-width: 900px) {
  .active-cards-header {
    text-align: center;
  }

  .active-cards-total {
    font-size: 1.25rem;
  }
}
.import-cards-found {
  width: 100%;
}

.import-cards-cards:nth-child(odd) {
  background-color: #eae8e7;
}

.import-cards-heading {
  width: 100%;
  margin-bottom: 20px;
}

.import-cards-box {
  margin-top: 30px;
  padding: 40px;
  max-width: 500px;
  width: 90%;
  height: 334px;
  background-color: #edecea;
  border-radius: 10px;
  display: block;
}
.import-cards-box span {
  font-size: 20px;
}

.import-cards-select-file {
  background-color: #fbfdfe;
  border-radius: 5px;
  border: 2px solid #9db7ce;
  color: #7aa1c2;
  font-size: 13px;
  width: 120px;
  height: 32px;
}
.import-cards-select-file:hover {
  background-color: #e6edf4;
}

.import-cards-file-info {
  font-size: 20px;
}

.import-cancel-btn {
  background-color: #fffdfa;
  border: 2px solid #243c57;
  color: #243c57;
  text-decoration: none;
  width: 250px;
  height: 44px;
  padding: 5px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.import-cancel-btn:hover {
  background-color: #e5eaee;
}

@media (max-width: 900px) {
  .import-cards-found {
    width: 90%;
  }

  .import-cards-heading {
    text-align: center;
  }

  .import-cards-info {
    width: 90%;
  }
}
@media (max-width: 500px) {
  .import-cards-box {
    padding: 30px;
  }
  .import-cards-box span {
    font-size: 16px;
  }

  .import-cards-file-info {
    flex-direction: column;
    font-size: 16px;
  }
}
.modal-background {
  background-color: #282828;
  opacity: 0.6;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 11;
}

.import-modal {
  position: fixed;
  right: 20px;
  left: 20px;
  top: 25%;
  bottom: 20%;
  z-index: 12;
  background-color: #fff;
  border-radius: 5px;
  height: fit-content;
  padding: 10px;
  max-width: 500px;
  margin: 0 auto;
}

.pricing .card {
  border-radius: 10px;
  transition: all 0.2s;
  border: solid 1px #c7c7c7;
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
  background-color: #1e3d59;
  width: 100%;
}

/* Hover Effects on Card */
@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  .pricing .card:hover .btn {
    opacity: 1;
  }
}
.pricing .card {
  border-radius: 10px;
  transition: all 0.2s;
  border: solid 1px #c7c7c7;
}

.pricing hr {
  margin: 1.5rem 0;
}

.pricing .card-title {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
}

.pricing .card-price {
  font-size: 3rem;
  margin: 0;
}

.pricing .card-price .period {
  font-size: 0.8rem;
}

.pricing ul li {
  margin-bottom: 1rem;
}

.pricing .text-muted {
  opacity: 0.7;
}

.pricing .btn {
  font-size: 80%;
  border-radius: 5rem;
  letter-spacing: 0.1rem;
  font-weight: bold;
  padding: 1rem;
  opacity: 0.7;
  transition: all 0.2s;
  background-color: #1e3d59;
  width: 100%;
}

/* Hover Effects on Card */
@media (min-width: 992px) {
  .pricing .card:hover {
    margin-top: -0.25rem;
    margin-bottom: 0.25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  .pricing .card:hover .btn {
    opacity: 1;
  }
}
.my-account-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.my-account-heading {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 40px;
  width: 93%;
}

.my-account-profile {
  width: 45%;
}

.my-account-sub {
  width: 45%;
}

.test-background {
  background-color: #44444480;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.reset-password-modal {
  height: 354px;
  width: 80%;
  max-width: 590px;
  background-color: white;
  z-index: 12;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  border: none;
  margin-left: 200px;
}
.reset-password-modal .reset-modal-text {
  padding-left: 20px;
  font-size: 18px;
  font-weight: 500;
}

.test-modal {
  position: absolute;
  top: 35%;
  width: 60%;
  z-index: 12;
  background-color: #fff;
  border-radius: 5px;
  height: fit-content;
  padding: 10px;
}

.back-btn {
  border: 2px solid #243c57;
  background-color: white;
  color: #243c57;
  text-decoration: none;
  width: 250px;
  height: 44px;
  padding: 5px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-btn:hover {
  background-color: #e5eaee;
}

.profile-modal-all-inputs input {
  border: 1px solid gray;
  height: 50px;
  padding: 8px;
}

.profile-modal-input-group {
  display: flex;
  justify-content: space-between;
}
.profile-modal-input-group input {
  width: 30%;
  border: 1px solid gray;
  height: 50px;
  padding: 8px;
}

.profile-modal-btn-group {
  display: flex;
  margin: 20px;
}
.profile-modal-btn-group button {
  width: 45%;
}

@media (max-width: 1000px) {
  .reset-password-modal {
    margin-left: 0;
    min-width: 270px;
    width: 60%;
  }
  .reset-password-modal .reset-modal-text {
    font-size: 15px;
  }
}
@media (max-width: 800px) {
  .my-account-container {
    flex-direction: column;
    align-items: center;
  }

  .my-account-heading {
    text-align: center;
  }

  .my-account-profile {
    width: 90%;
    margin-bottom: 40px;
  }

  .my-account-sub {
    width: 90%;
    margin-bottom: 40px;
  }

  .profile-modal-input-group {
    flex-direction: column;
  }
  .profile-modal-input-group input {
    width: 100%;
  }

  .profile-modal-btn-group {
    flex-direction: column;
    margin-bottom: 0;
    margin-top: 0;
  }
  .profile-modal-btn-group button {
    width: 100%;
  }
}
.profile-btn-modal {
  background-color: #1e3d59;
  border: 1px solid black;
  color: #fff;
  font-size: 1.3rem;
  padding: 2px 10px 2px 10px;
}

.profile-btn {
  text-align: center;
  color: #fff;
  background-color: #1e3d59;
  border: 1px solid black;
  font-size: 1.3rem;
  text-decoration: none;
  width: 307px;
}
.profile-btn:hover {
  color: #fff;
  background-color: #6894ba;
}

@media only screen and (max-width: 750px) {
  .profile-btn {
    font-size: 1.1rem;
    width: 250px;
  }

  h5 {
    font: 1em sans-serif;
  }
}
.tooltip-text {
  color: #fff;
  padding: 5px 4px 11px 4px;
  max-width: 300px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.2em;
  border-radius: 4px;
  background-color: rgba(97, 97, 97, 0.9);
  position: absolute;
  left: 0px;
  margin-top: 107px;
  z-index: 1;
  opacity: 0;
  transform: scale(0.05) translateY(-80%);
  transition: opacity 133ms, transform 100ms;
}

.create-cards-heading {
  width: 100%;
}

.create-cards-help-text {
  width: 100%;
  margin: 30px 0px 45px;
  text-align: center;
}

.alert-container {
  width: 100%;
  background-color: #fbeaea;
  color: #d32f2f;
  height: 37px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding: 24px 10px;
  font-weight: 500;
  font-size: 15px;
}

.generate-btn:disabled,
.generate-btn[disabled] {
  border: 1px solid #cccccc;
  background-color: #cccccc;
  color: #666666;
}

@media (max-width: 650px) {
  .create-cards-heading {
    width: 100%;
    text-align: center;
  }

  .create-cards-help-text {
    width: 90%;
  }
}
.import-cards-found {
  width: 100%;
}

.import-cards-cards:nth-child(odd) {
  background-color: #eae8e7;
}

.import-cards-heading {
  width: 100%;
  margin-bottom: 20px;
}

.import-cards-box {
  margin-top: 30px;
  padding: 40px;
  max-width: 500px;
  width: 90%;
  height: 334px;
  background-color: #edecea;
  border-radius: 10px;
  display: block;
}
.import-cards-box span {
  font-size: 20px;
}

.import-cards-select-file {
  background-color: #fbfdfe;
  border-radius: 5px;
  border: 2px solid #9db7ce;
  color: #7aa1c2;
  font-size: 13px;
  width: 120px;
  height: 32px;
}
.import-cards-select-file:hover {
  background-color: #e6edf4;
}

.import-cards-file-info {
  font-size: 20px;
}

.import-cancel-btn {
  background-color: #fffdfa;
  border: 2px solid #243c57;
  color: #243c57;
  text-decoration: none;
  width: 250px;
  height: 44px;
  padding: 5px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.import-cancel-btn:hover {
  background-color: #e5eaee;
}

@media (max-width: 900px) {
  .import-cards-found {
    width: 90%;
  }

  .import-cards-heading {
    text-align: center;
  }

  .import-cards-info {
    width: 90%;
  }
}
@media (max-width: 500px) {
  .import-cards-box {
    padding: 30px;
  }
  .import-cards-box span {
    font-size: 16px;
  }

  .import-cards-file-info {
    flex-direction: column;
    font-size: 16px;
  }
}
.modal-background {
  background-color: #282828;
  opacity: 0.6;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 11;
}

.import-modal {
  position: fixed;
  right: 20px;
  left: 20px;
  top: 25%;
  bottom: 20%;
  z-index: 12;
  background-color: #fff;
  border-radius: 5px;
  height: fit-content;
  padding: 10px;
  max-width: 500px;
  margin: 0 auto;
}

.welcome-text {
  width: 100%;
  margin: 20px 0px 20px 25px;
}

.subscription-plan-card {
  background-color: #edecea;
  border-radius: 10px;
  height: 120px;
  width: 98%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px;
}
.subscription-plan-card .sub-cards-info {
  display: flex;
  width: 25%;
  justify-content: space-between;
  align-items: center;
}

.month-cards-container {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.monthly-sales-card {
  background-color: #edecea;
  border-radius: 10px;
  height: 250px;
  max-width: 548px;
  width: 100%;
  padding: 24px;
  margin: 10px;
}

.monthly-sales-data {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.monthly-sales-cards-sold {
  text-align: center;
  width: 50%;
}

.small-cards-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
}

.check-card-balance {
  background-color: #edecea;
  border-radius: 10px;
  height: 200px;
  width: 360px;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
}

.create-gift-cards {
  background-color: #edecea;
  border-radius: 10px;
  height: 200px;
  width: 360px;
  padding: 24px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
}

.liability-report {
  background-color: #edecea;
  border-radius: 10px;
  height: 200px;
  width: 360px;
  padding: 24px 30px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (max-width: 1100px) {
  .monthly-sales-card {
    height: 285px;
  }

  .monthly-sales-data {
    align-items: flex-start;
    flex-direction: column;
  }

  .monthly-sales-divider {
    display: none;
  }

  .monthly-sales-cards-sold {
    text-align: left;
  }
}
@media (max-width: 800px) {
  .welcome-text {
    text-align: center;
    margin: 20px 0px 20px 0px;
  }

  .subscription-plan-card {
    padding: 0 24px;
    height: 275px;
    width: 400px;
    flex-direction: column;
  }
  .subscription-plan-card .sub-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .subscription-plan-card .sub-divider {
    display: none;
  }
  .subscription-plan-card .my-plan {
    width: 100%;
  }
  .subscription-plan-card .sub-cards-info {
    width: 100%;
  }
  .subscription-plan-card .sub-plan-btn {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .month-cards-container {
    flex-direction: column;
    align-items: center;
  }

  .monthly-sales-card {
    width: 400px;
  }
}
@media (max-width: 450px) {
  .subscription-plan-card {
    width: 300px;
  }

  .monthly-sales-card {
    width: 300px;
  }

  .check-card-balance {
    width: 300px;
    margin-left: 0;
    margin-right: 0;
  }

  .create-gift-cards {
    width: 300px;
    margin-left: 0;
    margin-right: 0;
  }

  .liability-report {
    width: 300px;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (min-width: 300px) {
  .check-balance .error,
.check-balance .success {
    margin-top: 20px;
    padding: 20px;
    font-size: 1.3rem;
  }

  .balance-card {
    width: fit-content;
    min-width: 280px;
    max-width: 400px;
    min-height: 200px;
    max-height: 600px;
    position: relative;
    margin-top: 30px;
    border: solid 1px gray;
  }
  .balance-card .user-input input {
    color: black;
  }

  .get-balance-button {
    height: 40px;
    margin: 20vw auto 0;
    background-color: #1e3d59;
    border: solid 2px #1e3d59;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .get-balance-button:focus {
    background-color: #1e3d59;
    border: solid 2px #1e3d59;
  }
  .get-balance-button:hover {
    background-color: #6894ba;
    border: solid 2px #1e3d59;
  }
  .get-balance-button:active {
    background-color: #1e3d59;
  }
}
@media (min-width: 400px) {
  .balance-card {
    width: 90%;
  }
  .balance-card .card-number-label {
    font-size: 1.5rem;
  }
  .balance-card .user-input {
    font-size: 1.25rem;
    padding: 5px;
  }
}
@media (min-width: 500px) {
  .balance-card {
    padding: 10px 20px;
  }

  .get-balance-button {
    height: 50px;
    font-size: 1.25rem;
    margin-top: 50px;
  }
}
@media (min-width: 600px) {
  .card-number-helper {
    font-size: 1rem;
  }
}
@media (min-width: 1000px) {
  .balance-card {
    margin-top: 30px;
  }
}
.active-cards-total {
  font-size: 1.5rem;
}

@media (max-width: 900px) {
  .active-cards-header {
    text-align: center;
  }

  .active-cards-total {
    font-size: 1.25rem;
  }
}
.inactive-cards-header {
  margin: 20px 0;
}

.inactive-cards-total {
  font-size: 1.5rem;
  margin-bottom: 14px;
}

@media (max-width: 900px) {
  .inactive-cards-header {
    text-align: center;
  }

  .inactive-cards-total {
    font-size: 1.25rem;
  }
}
.import-more-btn {
  height: 44px;
  width: 200px;
  font-size: 20px;
  color: #243c57;
  border-radius: 4px;
  background-color: #fff;
}
.import-more-btn:hover {
  background-color: #e5eaee;
}

.imported-total-cards {
  font-size: 1.5rem;
}

@media (max-width: 900px) {
  .imported-cards-header {
    text-align: center;
  }

  .imported-total-cards {
    font-size: 1.25rem;
  }
}
.used-cards-report {
  position: relative;
}
.used-cards-report .show-dot-menu {
  display: inline;
}
.used-cards-report .hide-dot-menu {
  display: none;
}
.used-cards-report .activeCard {
  max-width: 80%;
}
.used-cards-report .report-ellipses {
  position: fixed;
  right: 3.5%;
  margin-top: 8%;
  font-size: 2rem;
  cursor: pointer;
}
.used-cards-report .ellipses-menu {
  height: 9vh;
  width: 5vw;
  color: white;
  padding-bottom: 55px;
  padding-top: 20px;
  font-size: 1.2rem;
  text-decoration: none;
}
.used-cards-report .modal-background {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #282828;
  opacity: 0.6;
  z-index: 20;
}
.used-cards-report .delete-used-cards-modal {
  position: fixed;
  left: 5vw;
  right: 5vw;
  top: 40vw;
  min-width: 200px;
  height: fit-content;
  background-color: white;
  z-index: 20;
  padding: 1.5rem;
  border-radius: 5px;
}
.used-cards-report .delete-used-cards-modal h5 {
  font-weight: bold;
  margin-bottom: 1rem;
}
.used-cards-report .delete-used-cards-modal section {
  background-color: lightgoldenrodyellow;
  color: darkgoldenrod;
  padding: 10px;
  margin-bottom: 20px;
}
.used-cards-report .delete-used-cards-modal section h6 {
  font-weight: bold;
}
.used-cards-report .delete-used-cards-modal section p {
  margin-bottom: 0;
}
.used-cards-report .delete-used-cards-modal button {
  width: fit-content;
  height: fit-content;
  min-width: 100px;
  padding: 0.25rem;
  border-radius: 5px;
}
.used-cards-report .delete-used-cards-modal button:hover {
  opacity: 0.9;
}
.used-cards-report .delete-used-cards-modal button:nth-of-type(1) {
  margin-right: 15px;
}

@media (min-width: 600px) {
  .used-cards-report .delete-used-cards-modal {
    left: 15vw;
    right: 15vw;
    top: 30vw;
  }
  .used-cards-report .delete-used-cards-modal h5 {
    font-size: 1.5rem;
  }
  .used-cards-report .delete-used-cards-modal section h6 {
    font-size: 1.25rem;
  }
  .used-cards-report .delete-used-cards-modal section p {
    font-size: 1.25rem;
  }
  .used-cards-report .delete-used-cards-modal button {
    font-size: 1.25rem;
    min-width: 150px;
  }
}
@media (min-width: 700px) {
  .used-cards-report .activeCard {
    margin-left: 40px;
    font-size: 1.25rem;
  }
  .used-cards-report .total-active-cards {
    font-size: 1.5rem;
    margin-left: 40px;
  }
}
@media (min-width: 900px) {
  .used-cards-report .activeCard tbody > * {
    width: fit-content;
  }
  .used-cards-report .activeCard .tableActions {
    display: flex;
  }
  .used-cards-report .activeCard .tableActions div {
    margin: 0 10px;
    font-size: 1.25rem;
    cursor: pointer;
  }
  .used-cards-report .activeCard .tableActions div > * {
    margin: 0 10px;
  }
  .used-cards-report .delete-used-cards-modal {
    left: 20vw;
    right: 20vw;
    top: 25vh;
  }
}
@media (min-width: 1100px) {
  .used-cards-report .delete-used-cards-modal {
    left: 25vw;
    right: 25vw;
  }
}
@media (min-width: 1400px) {
  .used-cards-report .delete-used-cards-modal {
    left: 30vw;
    right: 30vw;
  }
}
@media (min-width: 1700px) {
  .used-cards-report .delete-used-cards-modal {
    left: 35vw;
    right: 35vw;
  }
}
.terms {
  font-size: 1rem;
}

input[type=checkbox] {
  accent-color: #7A4E09;
}

input {
  border-radius: 4px;
}

.card-top {
  height: 75%;
  display: flex;
}

.qr-code {
  font-size: larger;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  width: 38%;
  height: 100%;
}

.business-details {
  overflow: hidden;
  height: 100%;
  width: 62%;
  font-size: small;
  text-align: center;
  padding-top: 6px;
}

.terms-of-use {
  height: 25%;
  padding: 10px;
  font-size: xx-small;
}

.company-name {
  font-size: 15px;
  font-weight: bold;
}

.gen-pdf-btn {
  background-color: #6894BA;
  color: white;
  width: 400px;
  height: 42px;
  font-weight: 600;
  font-size: 15px;
  border: none;
  border-radius: 4px;
  padding: 0px;
}
.gen-pdf-btn:hover, .gen-pdf-btn:focus {
  color: white;
  background-color: #4D7DA8;
  border: none;
}

.disabled {
  background-color: #e0dedc;
  color: #b5b3b1;
}
.disabled:hover, .disabled:focus {
  background-color: #e0dedc;
  color: #b5b3b1;
}

.controls {
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 132px;
  height: 36px;
  border: 2px solid #4D7DA8;
  border-radius: 20px;
  font-size: 15px;
}

.left-right-container {
  display: flex;
  margin: 0 auto 20px auto;
  max-width: 1200px;
}

.left {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right {
  overflow: hidden;
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.logo-container {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.preview-text {
  text-align: "left";
  font-size: 22px;
  width: 428px;
  margin-bottom: -20px;
  margin-top: 20px;
}

@media only screen and (max-width: 850px) {
  .left-right-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }

  .left {
    width: 100%;
  }

  .right {
    width: 100%;
  }

  .logo-container {
    justify-content: center;
  }

  .preview-text {
    margin-bottom: 0px;
  }

  .adjust-controls {
    margin: 40px 0;
  }
}
.usage-log:nth-child(odd) {
  background-color: #eaeaeb;
}

.table-row:hover {
  cursor: pointer;
  background-color: rgba(104, 148, 186, 0.1);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.hide {
  opacity: 0;
}

.error,
.success,
.alert {
  margin-top: 20px;
  padding: 20px;
  font-size: 1.3rem;
}

.no-cards-message {
  display: block;
  text-align: center;
  margin-top: 50px;
  font-size: 1.25rem;
}

/*# sourceMappingURL=main.css.map */
