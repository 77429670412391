.pricing-card-top {
    border-bottom: 2px solid #d5d5d5;
    height: 50%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.pricing-card-btn-container {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.pricing-card-bottom {
    display: flex;
    flex-direction: column;
}

.two-card-container {
    display: flex;
    flex-wrap: wrap;
    width: 500px;
    justify-content: space-around;
}

.pricing-card-button {
    margin-top: -21px;
    height: 40px;
    width: 192px;
    border-radius: 20px;
    background-color: #1e3d59;
    border: solid 2px #1e3d59;
    display: flex;
    align-items: center;
    justify-content: center;

    &:focus {
      background-color: #1e3d59;
      border: solid 2px #1e3d59;
    }

    &:hover {
      background-color: #6894ba;
      border: solid 2px #1e3d59;
    }

    &:active {
      background-color: #1e3d59;
    }
}

.pricing-card-bot-info {
    font-size: 12px;
    padding-left: 20px;
    text-align: left;
    margin-top: 20px; 
}

@media only screen and (max-width: 550px){
    .two-card-container {
        display: flex;
        flex-wrap: wrap;
        max-width: 450px;
        width: 100%;
        justify-content: space-around;
    }
}