.app-container {
  margin: 20px 10px 20px 200px;
  border: 1px solid;
  min-height: calc(100vh - 40px);
  display: flex;
  flex-grow: 1;
  border-radius: 30px;
  background-color: white;
}

@media (max-width: 1000px) {
  .app-container {
    margin: 50px 10px 20px 10px;
  }
}
