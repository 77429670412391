.login-gift-logo {
  display: block;
}

.login-small-gift-logo {
  display: none;
}

.login-logo-container {
  display: flex;
  margin-left: 50px;
  margin-bottom: 36px;
}

@media only screen and (max-width: 850px) {
  .login-gift-logo {
    display: none;
  }

  .login-small-gift-logo {
    display: block;
  }

  .login-logo-container {
    justify-content: center;
    margin-left: 0;
  }
}
