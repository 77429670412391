.help-page-container {
  width: 80%;
}

.help-checkbox {
  cursor: pointer;
  margin-top: 2px;
}

.help-card {
  // transition: height .10s ease-in-out;
  margin-left: 40px;
  overflow: hidden;
  height: 100%;
  font-size: 14px;
}

.help-item-title {
  color: #6894ba;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
}

@media only screen and (max-width: 500px) {
  .help-page-container {
    width: 90%;
  }
  .help-checkbox {
    cursor: pointer;
    margin-top: -1px;
  }
  .help-title {
    text-align: center;
  }
  .help-card {
    margin-left: 20px;
    font-size: 12px;
  }
  .help-item-title {
    font-size: 16px;
  }
}
