.logout-logo-container {
  display: flex;
  justify-content: left;
  margin-bottom: 30px;
  margin-left: 50px;
}

.logout-see-you {
  font-size: 38px;
  color: rgb(36, 60, 87);
  font-size: 70px;
  font-weight: 700;
  margin-right: 20px;
}

.logout-small-gift-logo {
  display: none;
}

.logout-gift-logo {
  display: block;
}

.logout-text {
  color: white;
  font-size: 30px;
  margin-bottom: 80px;
}

.logout-login-btn {
  width: 300px;
  margin-bottom: 20px;
}

@media only screen and (max-width: 850px) {
  .logout-see-you {
    font-size: 38px;
  }

  .logout-small-gift-logo {
    display: block;
  }

  .logout-gift-logo {
    display: none;
  }
  .logout-text {
    font-size: 16px;
    max-width: 80%;
  }
  .logout-login-btn {
    width: 250px;
  }
}

@media only screen and (max-width: 600px) {
  .logout-logo-container {
    justify-content: center;
    margin-left: 0;
  }
}
